import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import DiscountGroupForm from './form/DiscountGroupForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class AddDiscountGroup extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Button className="full-size-width" type="primary" icon="plus">
                    <Trans>Pridanie rabatovej skupiny</Trans>
                </Button>
            }
            values={{isEdit: false}}
            onFinishSuccessful={reloadData}
            title={<Trans>Pridanie rabatovej skupiny</Trans>}
            Form={DiscountGroupForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa pridať rabatovú skupinu`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(AddDiscountGroup);