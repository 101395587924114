import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {DiscountRule} from '../../../constants/propTypesDefinitions';
import {createFetchDiscountRemoveRule} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class RemoveDiscountRule extends React.PureComponent {
    static propTypes = {
        rule: DiscountRule.isRequired,
        reload: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    render() {
        const {rule, reload, disabled} = this.props;
        if (rule == null || rule.is_inherited)
            return null;

        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Odstránenie rabatového pravidla</Trans>}>
                    <Button disabled={disabled} size="small" type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Odstránenie rabatového pravidla</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť rabatové pravidlo?</Trans>,
                createFetchDiscountRemoveRule(rule.id_customer, rule.id_discount_group),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť rabatové pravidlo.`)}
        />;
    }

}

export default RemoveDiscountRule;