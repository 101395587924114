import React from 'react';
import PropTypes from 'prop-types';
import PriceLevelSelect from '../../../project/PriceLevelSelect';
import DiscountTreeSelect from '../../../project/DiscountTreeSelect';
import EmployeesSelect from '../../../project/EmployeesSelect';
import locationHOC from '../../../locationProvider/locationHOC';
import {RIGHTS} from '../../../../constants/Rights';
import RightsWrapper from '../../../sessionProvider/RightsWrapper';
import {rationToPercent} from '../../../../lib/computation';
import {Trans, t} from '@lingui/macro';
import {DiscountGroupInTree} from '../../../../constants/propTypesDefinitions';
import {Button, Form} from 'antd';
import {createFetchDiscountEditRule} from '../../../../backend/apiCalls';
import {percentToRation} from '../../../../lib/computation';
import {
    formItemLayout,
    tailFormItemLayout,
    mandatoryRule,
    numberMandatoryRule,
    max99Rule,
    min100Rule
} from '../../../../constants/form';
import InputQuantity from '../../../project/InputQuantity';
import CustomersSelect from '../../../project/CustomersSelect';
import withSessionHOC from '../../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../../sessionProvider/SessionProvider';
const FormItem = Form.Item;

/**
 * @fero
 */

class AddDiscountRuleForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            isEdit: PropTypes.bool.isRequired,
            discountGroup: DiscountGroupInTree,
            customerId: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                let formValuesRatio = {
                    id_customer: values != null ? values.customerId : null,
                    id_ref_customer: formValues.id_ref_customer,
                    id_discount_group: formValues.id_discount_group,
                    ref_ratio: percentToRation(formValues.ref_ratio),
                    bulk_ratio: percentToRation(formValues.bulk_ratio),
                    pallet_ratio: percentToRation(formValues.pallet_ratio),
                    id_dealer: formValues.id_dealer != null ? formValues.id_dealer : null,
                };
                this.setState({loading: true});
                createFetchDiscountEditRule()(formValuesRatio).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const {isEdit} = values;
        const discountGroup = values != null ? values.discountGroup : null;
        const discountGroupChecked = discountGroup != null ? discountGroup : {};
        const discountRuleChecked = discountGroupChecked.rule != null ? discountGroupChecked.rule : {};
        const {getFieldDecorator} = this.props.form;
        const groupId = discountGroupChecked.id;
        return <Form onSubmit={this.handleSubmit}>
            {rights < RIGHTS.SUPERVISOR ?
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Ceny ako</Trans>}>
                    {getFieldDecorator('id_ref_customer', {initialValue: discountRuleChecked.id_ref_customer})(
                        <PriceLevelSelect focus={true}/>
                    )}
                </FormItem>
                :
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Ceny ako</Trans>}>
                    {getFieldDecorator('id_ref_customer', {initialValue: discountRuleChecked.id_ref_customer})(
                        <CustomersSelect focus={true}/>
                    )}
                </FormItem>
            }
            <FormItem
                {...formItemLayout}
                label={<Trans>Skupina</Trans>}>
                {getFieldDecorator('id_discount_group', {
                    rules: [mandatoryRule], initialValue: groupId
                })(
                    <DiscountTreeSelect disabled={isEdit} customRootId={groupId}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Prirážka(+) / Zľava(-)</Trans>}
            >
                {getFieldDecorator('ref_ratio', {
                    rules: [numberMandatoryRule, max99Rule, min100Rule],
                    initialValue: discountRuleChecked.ref_ratio != null ? rationToPercent(discountRuleChecked.ref_ratio) : 0,
                })(
                    <InputQuantity unit="%"/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Balík</Trans>}
            >
                {getFieldDecorator('bulk_ratio', {
                    rules: [numberMandatoryRule, max99Rule, min100Rule],
                    initialValue: discountRuleChecked.ref_ratio != null ? rationToPercent(discountRuleChecked.bulk_ratio) : 0,
                })(
                    <InputQuantity unit="%"/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Paleta</Trans>}
            >
                {getFieldDecorator('pallet_ratio', {
                    rules: [numberMandatoryRule, max99Rule, min100Rule],
                    initialValue: discountRuleChecked.ref_ratio != null ? rationToPercent(discountRuleChecked.pallet_ratio) : 0,
                })(
                    <InputQuantity unit="%"/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.ADMIN}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Nastavil</Trans>}
            >
                {getFieldDecorator('id_dealer', {
                    initialValue: discountRuleChecked.id_dealer != null ? discountRuleChecked.id_dealer : null,
                })(
                    <EmployeesSelect/>
                )}
            </FormItem>
            </RightsWrapper>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>;
    }

}

export default Form.create()(
    withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(
        locationHOC(AddDiscountRuleForm)
    )
);