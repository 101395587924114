import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import {getTreePath, searchTreeById} from '../../lib/object';
import {DiscountGroupInTree} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {TreeSelect} from 'antd';
import {Trans, t} from '@lingui/macro';
const TreeNode = TreeSelect.TreeNode;

/**
 * @fero
 */

class DiscountTreeSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        allowClear: PropTypes.bool,
        customRootId: PropTypes.string,
        [GLOBAL_DATA.DISCOUNT_TREE]: DiscountGroupInTree,
    };

    componentDidMount() {
        const discountTree = this.props[GLOBAL_DATA.DISCOUNT_TREE];
        const {onChange, value, allowClear} = this.props;
        if (value == null && !allowClear) {
            const firstGroup = discountTree.id;
            onChange(firstGroup);
        }
    }

    tree(node) {
        return <TreeNode
            value={node.id}
            title={node.name + ' (' + node.description + ')'}
            key={node.id}
        >
            {node.children != null ?
                node.children.map((child) => {
                    return this.tree(child)
                }) :
                null}
        </TreeNode>;
    }

    treeData() {
        const discountTree = this.props[GLOBAL_DATA.DISCOUNT_TREE];
        const {customRootId} = this.props;
        if(customRootId != null) {
            const foundNode = searchTreeById(discountTree, customRootId);
            return foundNode != null ? foundNode : {};
        } else {
            return discountTree;
        }
    }

    render() {
        const {value, [GLOBAL_DATA.DISCOUNT_TREE]: discountTree,  ...props} = this.props;
        const treeData = this.treeData();
        const pathKeys = getTreePath(treeData, value);
        if (treeData != null) {
            return <TreeSelect
                showSearch
                placeholder={<Trans>Vyberte...</Trans>}
                treeNodeFilterProp="title"
                treeDefaultExpandAll={false}
                treeDefaultExpandedKeys={pathKeys}
                value={value}
                {...props}
            >
                {this.tree(treeData)}

            </TreeSelect>
        } else {
            return null;
        }
    }
}

export default withDataHOC([GLOBAL_DATA.DISCOUNT_TREE])(DiscountTreeSelect);