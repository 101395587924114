import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import DiscountGroupForm from './form/DiscountGroupForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import {DiscountGroupInTree} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class EditDiscountGroup extends React.PureComponent {
    static propTypes = {
        group: DiscountGroupInTree,
        parentGroup: DiscountGroupInTree,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    render() {
        const {group, parentGroup} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Úprava rabatovej skupiny</Trans>}>
                    <Button className="mr-0" icon="edit" size="small"/>
                </Tooltip>
            }
            values={{isEdit: true, group: group, parentGroup: parentGroup}}
            onFinishSuccessful={reloadData}
            title={<Trans>Úprava rabatového priečinku</Trans>}
            Form={DiscountGroupForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť rabatový priečinok`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(EditDiscountGroup);