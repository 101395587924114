import React from 'react';
import PropTypes from 'prop-types';
import DiscountTreeSelect from '../../../project/DiscountTreeSelect';
import {merge} from '../../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Input} from 'antd';
import {createFetchDiscountAddGroup, createFetchDiscountEditGroup} from '../../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../../constants/form';
import {DiscountGroupInTree} from '../../../../constants/propTypesDefinitions';
const FormItem = Form.Item;

/**
 * @fero
 */

class DiscountGroupForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            parentGroup: DiscountGroupInTree,
            group: DiscountGroupInTree,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0);
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {isEdit, group} = values;
                const fetchFunction = isEdit ? createFetchDiscountEditGroup() : createFetchDiscountAddGroup();
                const checkedValues = isEdit ?
                    merge(formValues, {id: group != null ? group.id : null}) : formValues;
                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {isEdit, group, parentGroup}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        const groupName = group != null ? group.name : null;
        const groupDescription = group != null ? group.description : null;
        const parentId = parentGroup != null ? parentGroup.id : null;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: groupName})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            {!isEdit || parentGroup != null ? <FormItem
                {...formItemLayout}
                label={<Trans>Nadradená skupina</Trans>}>
                {getFieldDecorator('id_parent', {initialValue: parentId})(
                    <DiscountTreeSelect/>
                )}
            </FormItem> : null}
            <FormItem
                {...formItemLayout}
                label={<Trans>Popis</Trans>}
            >
                {getFieldDecorator('description', {initialValue: groupDescription})(
                    <Input/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }

}

export default Form.create()(DiscountGroupForm);