import React from 'react';
import PropTypes from 'prop-types';
import {rationToPercent} from '../../lib/computation';

/**
 * @fero
 */

class Ratio extends React.PureComponent {
    static propTypes = {
        ratio: PropTypes.number.isRequired,
    };

    render() {
        const {ratio} = this.props;
        return <span>{ratio >= 1 ? '+' : ''}{rationToPercent(ratio)}%</span>;
    }

}

export default Ratio;