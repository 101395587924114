import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import {CUSTOMER_TYPE} from '../../constants/customerTypes';
import {Customer} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class PriceLevelSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.CUSTOMERS]: PropTypes.arrayOf(Customer.isRequired).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        className: PropTypes.string,
        focus: PropTypes.bool,
        allowClear: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        const { [GLOBAL_DATA.CUSTOMERS]: customers,value, focus, className, ...props } = this.props;
        const valueChecked = value != null ? Number(value) : null;
        const priceLevels = customers.filter(customer => customer.id_type == CUSTOMER_TYPE.PRICE_LEVEL);
        return <Select
            className={"customers-select " + className}
            {...props}
            focus={focus}
            options={priceLevels.map(priceLevel => {
                return {
                    label: priceLevel.name,
                    value: priceLevel.id,
                }
            })}
            value={valueChecked}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.CUSTOMERS])(PriceLevelSelect);