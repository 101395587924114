import React from 'react';
import PropTypes from 'prop-types';
import GroupNode from './tree/GroupNode';
import withDataHOC from '../dataProvider/withDataHOC';
import {DiscountRulesTree} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {concatUnique} from '../../lib/array';
import {Tree} from 'antd';
import {Trans, t} from '@lingui/macro';
import {locationHOC} from '../locationProvider/locationHOC';
const TreeNode = Tree.TreeNode;

/**
 * @fero
 */

const rootKey = "D";

class DiscountGroupsTree extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.DISCOUNT_RULES]: DiscountRulesTree,
    };

    constructor(props) {
        super(props);
        const tree = this.props[GLOBAL_DATA.DISCOUNT_RULES];
        this.state = {
            expandedNodes: this.getNotInheritedNodes(tree),
            selectedNode: null,
        };
    }

    getNotInheritedNodes(tree, nodeIDs = []) {
        const rule = tree.rule != null ? tree.rule : null;
        if (rule != null && !rule.is_inherited)
            nodeIDs = concatUnique(nodeIDs, [tree.id_parent]);

        if (tree.children != null) {
            for (let i = 0; i < tree.children.length; i++)
                nodeIDs = this.getNotInheritedNodes(tree.children[i], nodeIDs);
        }
        return nodeIDs;
    }

    onSelect = (selectedKeys) => {
        const selectedKey = selectedKeys[0];
        this.setState({selectedNode: selectedKey});
    };

    onExpand = (expandedKeys) => {
        this.setState({expandedNodes: expandedKeys});
    };

    tree(node, parentGroup) {
        const {selectedNode} = this.state;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <TreeNode
            title={
                <GroupNode
                    currentSelectedGroup={selectedNode}
                    group={node}
                    parentGroup={parentGroup}
                    reload={() => {
                        reload([GLOBAL_DATA.DISCOUNT_RULES])
                    }}
                />}
            key={node.id}
        >
            {node.children != null ?
                node.children.map((child) => {
                    return this.tree(child, node)
                }) :
                null}
        </TreeNode>
    }

    render() {
        const {expandedNodes, selectedNode} = this.state;
        const discountTree = this.props[GLOBAL_DATA.DISCOUNT_RULES];
        return <div className="overflow-auto flex-item-dynamic-1">
            <div className="m-3">
                {discountTree != null ?
                    <Tree
                        selectedKeys={[selectedNode]}
                        expandedKeys={expandedNodes}
                        onExpand={this.onExpand}
                        onSelect={this.onSelect}
                    >
                        {this.tree(discountTree, null)}
                    </Tree> : null}
            </div>
        </div>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.DISCOUNT_RULES, GLOBAL_DATA.RELOAD_DATA])(DiscountGroupsTree));