import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import EditDiscountGroup from './EditDiscountGroup';
import RemoveDiscountGroup from './RemoveDiscountGroup';
import RemoveDiscountRule from './RemoveDiscountRule';
import EditDiscountRule from './EditDiscountRule';
import Ratio from '../../general/Ratio';
import Date from '../../general/Date';
import {getQueryParam} from '../../../lib/url';
import {RIGHTS} from '../../../constants/Rights';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {CUSTOMER_TYPE} from '../../../constants/customerTypes';
import {DiscountRulesTree, Location, CustomersArray} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import HistoryModal from '../../history/HistoryModal';

/**
 * @fero
 */

class GroupNode extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.CUSTOMERS]: CustomersArray.isRequired,
        parentGroup: DiscountRulesTree,
        group: DiscountRulesTree.isRequired,
        currentSelectedGroup: PropTypes.string,
        reload: PropTypes.func.isRequired,
        location: Location,
        rights: PropTypes.number.isRequired,
    };

    render() {
        const {location, [GLOBAL_DATA.CUSTOMERS]: customers, group, parentGroup, currentSelectedGroup, reload, rights} = this.props;
        const ruleChecked = group.rule != null ? group.rule : {};
        const customerId = getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER);
        const currentCustomer = customers.filter(customer => customer.id == customerId);
        const editRuleDisabled = (
            currentCustomer &&
            currentCustomer.length === 1 &&
            currentCustomer[0].id_type === CUSTOMER_TYPE.PRICE_LEVEL
        ) && rights < RIGHTS.SUPERVISOR;

        return <div className="discount-group-node full-size-width overflow-hidden">
            <div className="d-flex align-items-center">
                <span className="font-weight-bold">{group.name}</span>
                <RightsWrapper from={RIGHTS.SUPERVISOR}>
                { currentSelectedGroup == group.id ? 
                    <div className="d-flex pl-2">
                        <EditDiscountGroup key="edit" group={group} parentGroup={parentGroup}/>
                        <RemoveDiscountGroup key="remove" group={group}/>
                    </div>
                    : null
                }
                </RightsWrapper>
            </div>
            <div className="pl-1 pr-2 font-italic">{group.description}</div>
            <div className={"d-flex flex-wrap" + (ruleChecked.is_inherited ? " color-disabled" : "")}>
                    <span className="pl-2">
                        <Trans>ceny ako:</Trans>
                        {'  '}
                        {ruleChecked.ref_customer != null ? ruleChecked.ref_customer : <Trans>nedefinované</Trans>}
                        { ruleChecked.ref_ratio != null && Number(ruleChecked.ref_ratio) != 1 ?
                            <span>{'  '}<Ratio ratio={Number(ruleChecked.ref_ratio)}/></span>
                            : null
                        }
                        {','}
                    </span>
                    { ruleChecked.bulk_ratio != null && Number(ruleChecked.bulk_ratio) != 1 ?
                        <span className="pl-2">
                            <Trans>kartón:</Trans>
                            {'  '}
                            <Ratio ratio={Number(ruleChecked.bulk_ratio)}/>
                            {','}
                        </span>
                        : null
                    }
                    { ruleChecked.pallet_ratio != null && Number(ruleChecked.pallet_ratio) != 1 ?
                        <span className="pl-2">
                            <Trans>paleta:</Trans>
                            {'  '}
                            <Ratio ratio={Number(ruleChecked.pallet_ratio)}/>
                            {','}
                        </span>
                            : null
                    }
                    { ruleChecked.dealer != null ?
                        <span className="pl-2">
                            <Trans>nastavil:</Trans>
                            {'  '}
                            {ruleChecked.dealer}
                            {','}
                        </span>
                        : null
                    }
                    { ruleChecked.active_from != null ?
                        <span className="pl-2">
                            <Trans>platí od:</Trans>
                            {'  '}
                            <Date dateString={ruleChecked.active_from}/>
                        </span>
                        : null
                    }
                    {currentSelectedGroup == group.id ? 
                        <div className="d-flex pl-2">
                            <EditDiscountRule  disabled={editRuleDisabled} group={group} reload={reload}/>
                            <RemoveDiscountRule  disabled={editRuleDisabled} rule={group.rule} reload={reload}/>
                            <HistoryModal
                                size="small"
                                objectId={group.id}
                                objectType="discount"
                                objectName={group.name + (group.description != null ? '(' + group.description + ')' : '')}
                            />
                        </div>
                        : null
                    }
            </div>
        </div>;
    }

}

export default withSessionHOC()(locationHOC(withDataHOC([GLOBAL_DATA.CUSTOMERS])(GroupNode)));