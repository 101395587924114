import React from 'react';
import Helmet from '../project/Helmet';
import DiscountGroupsTree from './DiscountGroupsTree';
import AddDiscountGroup from './tree/AddDiscountGroup';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import SearchByLocation from '../general/SearchByLocation';
import {QUERY_PARAMS} from '../../constants/navigation';
import {CustomersArray} from '../../constants/propTypesDefinitions';
import {t} from '@lingui/macro';
import {RIGHTS} from '../../constants/Rights';
import { GLOBAL_DATA } from '../../constants/globalData';
import { getQueryParamNumber } from '../../lib/url';
import locationHOC from '../locationProvider/locationHOC';
import withDataHOC from '../dataProvider/withDataHOC';

/**
 * @fero
 */

class DiscountRulesDisplay extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.CUSTOMERS]: CustomersArray.isRequired,
    };

    render() {
        const {location, [GLOBAL_DATA.CUSTOMERS]: customers} = this.props;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        const customer = customerId != null && customers != null ? customers.find(c => c.id == customerId) : null;

        return <React.Fragment>
            <Helmet
                title={t`Rabaty` + (customer != null ? ' ' + customer.name : '')}
            />
            <div className="full-size-height full-size-width d-flex flex-column">
                <div className="p-3 flex-item-static add-max-width">
                    <SearchByLocation
                        textQueryParamKey={QUERY_PARAMS.DISCOUNT_RULES_PHRASE}
                    />
                </div>
                <RightsWrapper from={RIGHTS.SUPERVISOR}>
                    <div className="p-3 pt-0 flex-item-static add-max-width">
                        <AddDiscountGroup/>
                    </div>
                </RightsWrapper>
                <DiscountGroupsTree/>
            </div>
        </React.Fragment>;
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.CUSTOMERS])(DiscountRulesDisplay)
);