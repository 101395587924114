import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import DiscountRuleForm from './form/DiscountRuleForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {getQueryParam} from '../../../lib/url';
import {DiscountGroupInTree} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class EditDiscountRule extends React.PureComponent {
    static propTypes = {
        group: DiscountGroupInTree,
        reload: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    currentCustomerId() {
        const {location} = this.props;
        return getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER);
    }

    currentCustomerName() {
        const customers = this.props[GLOBAL_DATA.CUSTOMERS];
        const currentCustomerId = this.currentCustomerId();
        const currentCustomer = customers.find(customer => {
            return customer.id == currentCustomerId;
        });
        return currentCustomer != null ? currentCustomer.name : "NO CUSTOMER";
    }

    render() {
        const {reload, group, disabled} = this.props;
        const currentCustomerId = this.currentCustomerId();
        const currentCustomerName = this.currentCustomerName();
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Úprava rabatového pravidla</Trans>}>
                    <Button disabled={disabled} size="small" icon="edit"/>
                </Tooltip>
            }
            values={{
                discountGroup: group,
                isEdit: true,
                customerId: currentCustomerId,
            }}
            onFinish={reload}
            title={<span><Trans>Úprava rabatového pravidla pre</Trans>{' ' + currentCustomerName}</span>}
            Form={DiscountRuleForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť rabatové pravidlo`)}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.CUSTOMERS])(EditDiscountRule));